
import { AccountService } from "../services/account";
import { NetFlowVue } from "./NetFlowBaseVue";
import { InjectReactive, Prop, Watch } from "vue-property-decorator";

import TransactionTable from "../components/TransactionTable.vue";
import { Options } from "vue-class-component";
import dayjs from "dayjs";
import { NetflowTransaction } from "../../../shared/models/netflow-transaction";
import { SortUtils } from "../utils/sort-utils";

const DAYS_TO_LOAD = 30;

@Options({
  components: {
    TransactionTable,
  },
})
export default class AllTransactionsView extends NetFlowVue {
  @Prop(String) accountId!: string;

  transactions: NetflowTransaction[] = [];
  pendingTransactions: NetflowTransaction[] = [];
  searchTerm: any;
  endDate = new Date();
  startDate = new Date();
  pleaseWait = false;
  dayjs = dayjs;

  sortUtils = new SortUtils();

  private sortColumn = "date";
  private sortAsc = false;

  @InjectReactive() isInit!: boolean;
  @Watch("isInit", { immediate: true }) onIsInitChanged() {
    if (this.isInit) {
      this.resetDates();
      this.loadTransactions();
    }
  }

  resetDates() {
    this.endDate = new Date();
    this.startDate = dayjs().subtract(DAYS_TO_LOAD, "days").toDate();
  }

  async loadTransactions(append = false) {
    this.pleaseWait = true;
    const allTxns = await new AccountService().getUserTransactions(
      this.getAccessToken(),
      this.startDate,
      this.endDate,
      this.searchTerm ?? ""
    );
    this.pleaseWait = false;

    const permanentTxns = allTxns.filter((txn) => !txn.pending);
    const pendingTxnThatHavePermTxn = permanentTxns
      .filter((txn) => txn.pending_transaction_id)
      .map((txn) => txn.pending_transaction_id);

    const newTxns = allTxns.filter(
      (txn) => pendingTxnThatHavePermTxn.indexOf(txn.transaction_id) === -1
    );
    if (append) {
      this.transactions = [...this.transactions, ...newTxns];
    } else {
      this.transactions = newTxns;
    }
    this.sortUtils.sortTransactions(this.transactions, this.sortColumn, this.sortAsc);
  }

  searchClicked() {
    this.resetDates();
    this.loadTransactions();
  }

  loadOlder() {
    this.endDate = this.startDate;
    this.startDate = dayjs(this.endDate)
      .subtract(DAYS_TO_LOAD, "days")
      .toDate();
    this.loadTransactions(true);
  }

  sortClicked(collection: NetflowTransaction[], columName: string) {
    if (columName === this.sortColumn) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortColumn = columName;
    }
    this.sortUtils.sortTransactions(collection, this.sortColumn, this.sortAsc);
  }
}
